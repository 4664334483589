<template>
  <a
    v-loading="loading"
    @click="followStatus ? unfollowArtist() : followArtist()"
    id="kt_user_follow_button"
  >
    <span class="svg-icon svg-icon-3 d-none">
      <inline-svg src="/media/icons/duotune/arrows/arr012.svg" />
    </span>
    {{ followStatus ? "Unfollow" : "Follow" }}
  </a>
</template>
<script>
import { ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "Follow",
  emits: ["isFollowed:update"],
  props: {
    uuid: {
      type: String,
      default: "",
    },
    isFollowed: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const loading = ref(false);
    const followStatus = ref(props.isFollowed);

    async function followArtist() {
      loading.value = true;
      try {
        const isFollowed = await store.dispatch(
          "UserModule/followArtist",
          props.uuid
        );
        loading.value = false;
        if (isFollowed) {
          // emit("update:isFollowed", true);
          followStatus.value = true;
        }
      } catch (e) {
        loading.value = false;
        console.error(e);
      }
    }

    async function unfollowArtist() {
      loading.value = true;
      try {
        const artistUnfollowed = await store.dispatch(
          "UserModule/unfollowArtist",
          props.uuid
        );
        loading.value = false;
        if (artistUnfollowed) {
          // emit("update:isFollowed", false);
          followStatus.value = false;
        }
      } catch (e) {
        loading.value = false;
        console.error(e);
      }
    }

    watch(
      () => props.isFollowed,
      (value) => {
        followStatus.value = value;
      },
      { immediate: true }
    );

    return { loading, followArtist, unfollowArtist, followStatus };
  },
};
</script>
<style lang="scss" scoped>
a {
  color: var(--el-text-color-regular) !important;
}
</style>
