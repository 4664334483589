import httpClient from "./httpClient";

const HUBSPOT = "hubspot";
const MONGO_HUBSPOT = "mongo-hubspot";

const addArtistToCRM = (uuid) => httpClient.post(`${HUBSPOT}/single/${uuid}`);

const addBatchArtistToCRM = (crawledHistoryIds) =>
  httpClient.post(`${HUBSPOT}/batch`, { crawledHistoryIds });

const addArtistsToCRM = (payload) =>
  httpClient.post(`${MONGO_HUBSPOT}/add-artist-crm`, payload);

export { addArtistToCRM, addBatchArtistToCRM, addArtistsToCRM };
