<template>
  <div class="artist-menu-component" v-loading.fullscreen.lock="loading">
    <el-dropdown trigger="click">
      <button
        class="
          btn btn-sm btn-icon btn-bg-light btn-active-color-primary
          el-dropdown-link
        "
      >
        <i class="bi bi-three-dots fs-3"></i>
      </button>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            v-if="buttons.includes('forecast')"
            @click="
              routeArtistToUrl(
                `/ai-forecast/${artist.name}/${artist.spotifyID}`
              )
            "
            >Forecast
          </el-dropdown-item>
          <el-dropdown-item
            v-if="buttons.includes('score')"
            @click="routeArtistToUrl(`/score-analytics/${artist.spotifyID}`)"
            >Score
          </el-dropdown-item>
          <el-dropdown-item
            @click="fetchData(artist, 'download')"
            v-if="buttons.includes('download')"
          >
            Download
          </el-dropdown-item>
          <el-dropdown-item
            @click="fetchData(artist, 'downloadall')"
            v-if="buttons.includes('downloadAll')"
          >
            Download All
          </el-dropdown-item>
          <el-dropdown-item
            v-if="buttons.includes('update')"
            @click="updateArtist(artist)"
            >Update
          </el-dropdown-item>
          <el-dropdown-item
            v-if="buttons.includes('copyDetailPage')"
            @click="copyUrl(artist, 'detail')"
            >Copy Detail Page Url
          </el-dropdown-item>
          <el-dropdown-item
            v-if="buttons.includes('copyForecastPage')"
            @click="copyUrl(artist, 'forecast')"
            >Copy Forecast Page Url
          </el-dropdown-item>
          <el-dropdown-item
            v-if="buttons.includes('copyPublicPage')"
            @click="copyUrl(artist, 'public')"
            >Copy Public Page Url
          </el-dropdown-item>
          <el-dropdown-item
            v-if="buttons.includes('analytics')"
            @click="routeToAnalyticsPage(artist.name)"
            >Analytics
          </el-dropdown-item>
          <el-dropdown-item
            v-if="buttons.includes('artistDetails')"
            @click="routeToArtistDetailPage(artist.uuid)"
            >Artist Detail
          </el-dropdown-item>
          <el-dropdown-item
            v-if="buttons.includes('discover')"
            @click="routeToDiscoverPage"
            >Listen
          </el-dropdown-item>
          <el-dropdown-item
            v-if="buttons.includes('watchlist')"
            @click="showWatchListModal(artist.uuid)"
            >Watchlist
          </el-dropdown-item>
          <el-dropdown-item
            v-if="buttons.includes('crawl')"
            @click="routeToCrawlPage(artist.uuid)"
            >Crawl
          </el-dropdown-item>
          <el-dropdown-item
            v-if="buttons.includes('saveArtist')"
            @click="saveArtist(artist.uuid)"
            >Save Artist
          </el-dropdown-item>
          <el-dropdown-item
            v-if="buttons.includes('followV2')"
            @click="followUnFollow(artist.spotifyID)"
            >{{ followStatus ? "UnFollow" : "Follow" }}
          </el-dropdown-item>
          <el-dropdown-item v-if="showFollowButton">
            <Follow :uuid="artist.uuid" :is-followed="artist.isFollowed" />
          </el-dropdown-item>
          <el-dropdown-item
            v-if="buttons.includes('+CRM')"
            @click="addToCrm(artist.spotifyID)"
            >+CRM
          </el-dropdown-item>
          <el-dropdown-item
            v-if="buttons.includes('artistNewDesign')"
            @click="routeToNewDesignPage(artist)"
            >New Design
          </el-dropdown-item>
          <el-dropdown-item
            v-if="buttons.includes('valuation')"
            @click="routeArtistToUrl(`/artist-valuation/${artist.spotifyID}`)"
            >Valuation
          </el-dropdown-item>
          <el-dropdown-item
            v-if="buttons.includes('deleteArtist')"
            @click="deleteArtist(artist.spotifyID)"
            >Delete
          </el-dropdown-item>
          <el-dropdown-item
            v-if="buttons.includes('songReachProfile')"
            @click="routeToSongReach(artist.chartmetricID)"
            >SongReach Profile
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
import { DrawerComponent } from "@/assets/ts/components";
import Follow from "../../common/components/Follow.vue";
import { useRouter } from "vue-router";
import { ref } from "vue";
import { useStore } from "vuex";
import { getArtistUUIDBySpotifyPayload } from "@/api/artists.api";
import { ElNotification } from "element-plus";
import { addArtistsToCRM, addArtistToCRM } from "@/api/hubspot";
import {
  followUnFollowArtistV2,
  saveArtistApi,
  saveMongoArtistDetail,
} from "@/api/user.api";
import { watch } from "vue";
import { deleteArtistFromDB, getArtistUUIDBySpotifyID } from "@/api/mongoDB";
import Swal from "sweetalert2";
import { routeToPage } from "@/utils/staticHelper";

export default {
  name: "ArtistMenuComponent",
  props: {
    artist: {
      type: Object,
      required: true,
    },
    fromDrawer: {
      type: Boolean,
      default: false,
    },
    spotifyObject: {
      type: Object,
      default: null,
    },
    buttons: {
      type: Array,
      required: true,
    },
    showFollowButton: {
      type: Boolean,
      default: true,
    },
    isFollowedArtist: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Follow,
  },

  setup(props) {
    const addWatchlistModalMenuComponentRef = ref(null);
    const loading = ref(false);
    const followStatus = ref(false);
    const router = useRouter();
    const store = useStore();

    const startDownload = () => {
      loading.value = true;
    };
    const finishDownload = () => {
      loading.value = false;
    };
    const fetchData = async (artist, endPoint) => {
      try {
        window.open(
          `https://forecaster.blingrock.ai/${endPoint}?spotify_id=${artist.spotifyID}`,
          "_blank"
        );
      } catch (e) {
        console.log(e);
      }
    };

    const showWatchListModal = async (uuid) => {
      let uuidLocal = uuid ? uuid : await getArtistUUIDBySpotifyData();
      if (uuidLocal) {
        store.commit(
          "ArtistModule/SET_SHOW_WATCHLIST_MODAL_ARTIST_UUID",
          uuidLocal
        );
      }
    };

    const hideDrawer = () => {
      DrawerComponent.getInstance("artistViewDrawer").hide();
    };

    const routeToAnalyticsPage = (name) => {
      if (name) {
        window.open(`/artists?search=${name}`, "_blank");
      }
    };

    const routeToArtistDetailPage = async (uuid) => {
      let uuidLocal = uuid ? uuid : await getArtistUUIDBySpotifyData();
      if (uuidLocal) {
        if (props.fromDrawer) {
          hideDrawer();
          router.push({
            path: `/artists/${uuidLocal}/overview`,
          });
        } else {
          window.open(`/artists/${uuidLocal}/overview`, "_blank");
        }
      }
    };

    const getArtistUUIDBySpotifyData = async () => {
      if (props.spotifyObject && Object.keys(props.spotifyObject).length > 0) {
        loading.value = true;
        try {
          const { data } = await getArtistUUIDBySpotifyPayload(
            props.spotifyObject
          );
          return data.uuid;
        } catch (e) {
          ElNotification({
            title: "Error",
            message: "No match found for this artist",
            type: "error",
          });
        } finally {
          loading.value = false;
        }
      }
      return null;
    };

    const routeToDiscoverPage = () => {
      // if (props.fromDrawer) {
      //   hideDrawer();
      // }
      store.dispatch("DiscoverModule/changeSelectedMenu", "Search");
      window.open(`/discover?search=${props.artist.name}`, "_blank");
    };

    const routeToCrawlPage = async (uuid) => {
      loading.value = true;
      let uuidLocal = uuid ? uuid : await getArtistUUIDBySpotifyData();
      loading.value = false;
      window.open(
        `/crawler?search=${props.artist.name}${
          uuidLocal ? "&uuid=" + uuidLocal : ""
        }`,
        "_blank"
      );
    };

    const addToCrm = async (spotifyID) => {
      loading.value = true;
      if (spotifyID) {
        try {
          await addArtistsToCRM([{ spotifyID: spotifyID }]);
        } catch (e) {
          ElNotification({
            title: "Error",
            message: "Error adding to CRM, please try later!",
            type: "error",
          });
        } finally {
          loading.value = false;
          ElNotification({
            title: "Success",
            message: "Artist added to CRM!",
            type: "success",
          });
        }
      } else {
        ElNotification({
          title: "Error",
          message: "Error adding to CRM, please try later!",
          type: "error",
        });
      }
      loading.value = false;
    };
    const saveArtist = async (uuid) => {
      loading.value = true;
      let uuidLocal = uuid ? uuid : await getArtistUUIDBySpotifyData();
      if (uuidLocal) {
        try {
          await saveArtistApi(uuidLocal);
          await store.dispatch("UserModule/fetchDiscogsSearchHistory", true);
          localStorage.setItem(
            "fetch-discogs-search-history",
            Math.random() + "discogs-search-history"
          );
        } catch (e) {
          //
        } finally {
          loading.value = false;
        }
      } else {
        ElNotification({
          title: "Error",
          message: "Error in saving Artist, please try later!",
          type: "error",
        });
      }
      loading.value = false;
    };
    const routeToSandboxPage = (spotifyID) => {
      if (spotifyID) {
        window.open(
          `${process.env.VUE_APP_SANDBOX_URI}/?spotify_id=${spotifyID}`,
          "_blank"
        );
      }
    };
    const routeArtistToUrl = (url) => {
      if (url) {
        window.open(`${url}`, "_blank");
      }
    };
    const routeToNewDesignPage = async (artist) => {
      try {
        loading.value = true;
        if (artist.uuid) {
          await store.dispatch("ArtistModule/setArtistUUID", artist.uuid);
        } else {
          const { data } = await getArtistUUIDBySpotifyID(artist.spotifyID);
          await store.dispatch("ArtistModule/setArtistUUID", data);
        }
        window.open(`/ai-artist/${artist.spotifyID}`, "_blank");
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    const followUnFollow = async (spotifyID) => {
      try {
        loading.value = true;
        const { data } = await followUnFollowArtistV2(spotifyID);
        if (data.status === "success") {
          followStatus.value = !followStatus.value;
        }
        loading.value = false;
        ElNotification({
          title: "Success",
          message: data.message,
          type: "success",
        });
      } catch (e) {
        console.log(e);
      }
    };

    const updateArtist = (artist) => {
      try {
        loading.value = true;
        saveMongoArtistDetail(artist.spotifyID, true);
        store.dispatch(
          "ForecastModule/setUpdateListenersTrendGrowthStatus",
          true
        );
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    const copyUrl = (artist, type) => {
      if (type === "detail") {
        navigator.clipboard.writeText(
          `${window.location.origin}/ai-artist-extern/${artist.spotifyID}/${artist.uuid}`
        );
      } else if (type === "forecast") {
        navigator.clipboard.writeText(
          `${window.location.origin}/ai-forecast-extern/${artist.name}/${artist.spotifyID}`
        );
      } else if (type === "public") {
        navigator.clipboard.writeText(
          `${window.location.origin}/ai-artist-public/${artist.spotifyID}`
        );
      }
    };

    const deleteArtist = (spotifyID) => {
      Swal.fire({
        title: "Delete this Artist?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            loading.value = true;
            await deleteArtistFromDB(spotifyID);
            Swal.fire("Deleted!", "Artist has been deleted.", "success");
            loading.value = false;
            await routeToPage("/dashboard");
          }
        })
        .catch((e) => {
          console.error(e);
          Swal.fire("Oops!", "Error in deleting artist.", "error");
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const routeToSongReach = (chartmetricID) => {
      if (chartmetricID) {
        window.open(
          `https://songreach-stage.septemsystems.com/artist-detail/${chartmetricID}`,
          "_blank"
        );
      } else {
        ElNotification({
          title: "Error",
          message: "Artist ChartMetricID not found!",
          type: "error",
        });
      }
    };

    watch(
      () => props.isFollowedArtist,
      (value) => {
        followStatus.value = value;
      },
      { immediate: true }
    );
    return {
      addWatchlistModalMenuComponentRef,
      loading,
      followStatus,
      showWatchListModal,
      routeToArtistDetailPage,
      routeToDiscoverPage,
      routeToCrawlPage,
      routeToAnalyticsPage,
      addToCrm,
      saveArtist,
      routeToSandboxPage,
      routeToNewDesignPage,
      fetchData,
      startDownload,
      finishDownload,
      followUnFollow,
      updateArtist,
      copyUrl,
      routeArtistToUrl,
      deleteArtist,
      routeToSongReach,
    };
  },
};
</script>

<style scoped>
.el-dropdown-menu__item:hover {
  color: #009ef7 !important;
}
</style>
